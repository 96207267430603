import {
  getCookie,
  setCookie,
} from 'cookies-next';
import { type AppRouterOptions } from 'cookies-next/src/types';

import { type ListingType } from '@/features/listing/types/listing-types';

const cookieName = 'service_type';

export function getCookieServiceType(options?: AppRouterOptions): ListingType | null {
  const cookie = getCookie(cookieName, options);

  if (cookie) {
    return cookie.toLowerCase() as ListingType;
  }

  return null;
}

export function setCookieServiceType(type: ListingType): void {
  setCookie(cookieName, type);
}
