import {
  getCookie,
  setCookie,
} from 'cookies-next';

import { type Coordinates } from '@/features/place/types/place-types';

const cookieName = 'userCoordinates';

export function getCookieUserCoordinates(): Coordinates | null {
  const cookie = getCookie(cookieName);

  if (cookie) {
    return JSON.parse(cookie) as Coordinates;
  }

  return null;
}

export function setCookieUserCoordinates(coordinates: Coordinates) {
  const durationOneDay = 60 * 60 * 24;

  setCookie(cookieName, coordinates, {
    maxAge: durationOneDay,
    path: '/',
  });
}
