'use client';

import isEqual from 'lodash/isEqual';
import { useSearchParams } from 'next/navigation';
import { useLocale } from 'next-intl';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { type SingleValue } from 'react-select';
import {
  useIsMounted,
  useUnmount,
} from 'usehooks-ts';

import CurrencyInput from '@/features/currency/components/currency-picker/currency-input/currency-input';
import { setCookieCurrency } from '@/features/currency/cookies/cookie-currency';
import getCurrenciesRates from '@/features/currency/requests/get-currencies-rates';
import { type Currency } from '@/features/currency/types/currency-types';
import { LISTINGS_BUDGET_FILTER } from '@/features/listing/constants/listings-filters-constants';
import { getUserBearerCookie } from '@/features/user/cookies/cookie-user-bearer';
import updateLoggedUserLocalization from '@/features/user/requests/update-logged-user-localization';
import {
  usePathname,
  useRouter,
} from '@/navigation';
import {
  getTypedLocalStorageItem,
  removeTypedLocalStorageItem,
  setTypedLocalStorageItem,
} from '@/shared/utils/local-storage';
import Select from '@/ui/form-elements/select/select';

import CurrencyOption from '../currency-option/currency-option';
import CurrencyValue from '../currency-value/currency-value';

interface CurrencySelectProps {
  options: Currency[],
  currentCurrency: Currency['currency']
}

export default function CurrencySelect(props: CurrencySelectProps) {
  const {
    currentCurrency,
    options,
  } = props;

  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const isMounted = useIsMounted();
  const locale = useLocale();

  const optionWithCurrentCurrency = options.find((option) => option.currency === currentCurrency);

  const [selectedCurrency,
    setSelectedCurrency] = useState<SingleValue<Currency>>(optionWithCurrentCurrency ?? null);

  const calculateCurrency = (value: number, previousRate: number, selectedRate: number) => {
    const currencyWithRate = getTypedLocalStorageItem('currencyWithRate');
    const rates = getTypedLocalStorageItem('currenciesRates');
    const currentCurrencyRate = currencyWithRate ? rates?.[currencyWithRate.currency] : null;

    if (currencyWithRate?.previousRate && currentCurrencyRate && currencyWithRate.previousRate !== currentCurrencyRate) {
      const inUsd = value / previousRate;
      const maxInCurrentCurrency = inUsd * selectedRate;

      return Math.ceil(maxInCurrentCurrency);
    }

    return value;
  };

  const onChangeSelect = (option: SingleValue<Currency>) => {
    if (option) {
      // TODO: add error handling
      const rates = getTypedLocalStorageItem('currenciesRates');
      const selectedRate = rates?.[option.currency];
      const isUserLogged = getUserBearerCookie();

      const switchCurrency = () => {
        if (!rates || !selectedRate) {
          return;
        }

        const previousCurrency = selectedCurrency?.currency ?? 'USD';

        setSelectedCurrency(option);

        setCookieCurrency(option.currency);

        setTypedLocalStorageItem('currencyWithRate', {
          ...option,
          previousRate: rates[previousCurrency],
          rate: rates[option.currency],
        });

        const params = new URLSearchParams(searchParams.toString());
        const budgetMaxParameter = Number(params.get(LISTINGS_BUDGET_FILTER.options.max.name));
        const budgetMinParameter = Number(params.get(LISTINGS_BUDGET_FILTER.options.min.name));
        const previousRate = rates[previousCurrency];

        if (budgetMaxParameter && previousRate) {
          const nMax = String(calculateCurrency(budgetMaxParameter, previousRate, selectedRate));
          const nMin = String(calculateCurrency(budgetMinParameter, previousRate, selectedRate));

          params.set(LISTINGS_BUDGET_FILTER.options.max.name, nMax);
          params.set(LISTINGS_BUDGET_FILTER.options.min.name, nMin);

          router.push(`${pathname}?${params.toString()}`);
        }
        /* needed to trigger storage event for current tab,
             other tabs will be updated by storage event by default */
        window.dispatchEvent(new Event('storage'));
      };

      if (isUserLogged) {
        updateLoggedUserLocalization(locale, option.currency)
          .then(() => {
            switchCurrency();
          })
          .catch(() => null);
      } else {
        switchCurrency();
      }
    }
  };

  const getCurrencyName = (language: Currency) => language.currency;

  const handleListingRateChange = useCallback(() => {
    const currency = getTypedLocalStorageItem('currencyWithRate');

    if (currency && !isEqual(selectedCurrency, currency)) {
      setSelectedCurrency(currency);
    }
  }, [selectedCurrency]);

  useEffect(() => {
    const currencyWithRate = getTypedLocalStorageItem('currencyWithRate');
    // TODO: add error handling
    getCurrenciesRates()
      .then((rates) => {
        setTypedLocalStorageItem('currenciesRates', rates);

        if (currentCurrency !== currencyWithRate?.currency && optionWithCurrentCurrency) {
          setTypedLocalStorageItem('currencyWithRate', {
            ...optionWithCurrentCurrency,
            previousRate: currencyWithRate?.rate,
            rate: rates[optionWithCurrentCurrency.currency],
          });
        }

        window.dispatchEvent(new Event('budgetFilter'));
      })
      .catch(() => null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  useEffect(() => {
    window.addEventListener('storage', handleListingRateChange);
  }, [handleListingRateChange, isMounted]);

  useUnmount(() => {
    window.removeEventListener('storage', handleListingRateChange);
  });

  useUnmount(() => {
    removeTypedLocalStorageItem('currencyWithRate');
    removeTypedLocalStorageItem('currenciesRates');
  });

  return (
    <Select<Currency, false>
      value={selectedCurrency}
      instanceId="id-1312"
      options={options}
      onChange={onChangeSelect}
      getOptionLabel={getCurrencyName}
      getOptionValue={getCurrencyName}
      aria-label="currency"
      components={{
        Input: CurrencyInput,
        Option: CurrencyOption,
        SingleValue: CurrencyValue,
      }}
      isOptionDisabled={(option) => option.currency === selectedCurrency?.currency}
    />
  );
}
