import {
  type FiltersDefault,
  FiltersNames,
  type ListingsCheckboxFilterPath,
  type ListingsFilterToggle,
  type ListingsRangeFilter,
  PaginationNames,
  type Pet,
  type SexFilters,
  type Zodiac,
} from '@/features/listing/types/listing-filters-types';
import {
  type DefaultListingUrl,
  type ListingType,
} from '@/features/listing/types/listing-types';

const LISTINGS_PER_PAGE = 11;
export const LISTINGS_PER_PAGE_MAP = 500;

export const LISTINGS_DEFAULT_SEARCH_PARAMS: FiltersDefault = {
  [FiltersNames.ListingType]: 'haveshare',
  [FiltersNames.Sort]: 'LastActivity',
  [FiltersNames.GeoRadiusScale]: '1',
  [PaginationNames.PageSize]: String(LISTINGS_PER_PAGE),
};

export const LISTINGS_TYPE_FILTER: ListingsCheckboxFilterPath<ListingType, DefaultListingUrl, 'listingType'> = {
  changingHrefPart: {
    token: 'listingType',
    type: 'path',
  },
  filterType: 'radio',
  isOptionsTranslated: false,
  name: FiltersNames.ListingType,
  options: [
    {
      pathValue: 'rooms-for-rent',
      value: 'haveshare',
    },
    {
      pathValue: 'apartments-for-rent',
      value: 'haveapartment',
    },
    {
      pathValue: 'roommates',
      value: 'needroom',
    },
    {
      pathValue: 'tenants',
      value: 'needapartment',
    },
  ],
} as const;

export const LISTINGS_BUDGET_FILTER: ListingsRangeFilter = {
  filterTitle: 'budget',
  filterType: 'range',
  options: {
    max: {
      ariaLabel: 'price max',
      defaultValue: 5000,
      name: FiltersNames.BudgetMax,
      value: 5000,
    },
    min: {
      ariaLabel: 'price min',
      defaultValue: 0,
      name: FiltersNames.BudgetMin,
      value: 0,
    },
  },
  step: 10,
} as const;

export const LISTINGS_AGE_FILTER: ListingsRangeFilter = {
  filterTitle: 'age',
  filterType: 'range',
  options: {
    max: {
      ariaLabel: 'age max',
      defaultValue: 99,
      name: FiltersNames.AgeMax,
      value: 99,
    },
    min: {
      ariaLabel: 'age min',
      defaultValue: 18,
      name: FiltersNames.AgeMin,
      value: 18,
    },
  },
  step: 1,
} as const;

export const SEX_FILTER: ListingsFilterToggle<SexFilters> = {
  defaultValue: '170',
  filterTitle: 'gender',
  filterType: 'radio',
  isOptionsTranslated: true,
  name: FiltersNames.Sex,
  options: [
    {
      label: 'female',
      value: '159',
    },
    {
      label: 'male',
      value: '160',
    },
    {
      label: 'everyone',
      value: '170',
    },
  ],
  optionsTranslatedKey: 'sex',
} as const;

export const HOUSEHOLD_SEX_FILTER: ListingsFilterToggle<SexFilters> = {
  columns: '2',
  defaultValue: '1258560',
  filterTitle: 'household-gender',
  filterType: 'radio',
  isOptionsTranslated: true,
  name: FiltersNames.HouseholdSex,
  options: [
    {
      label: 'female',
      value: '1258548',
    },
    {
      label: 'male',
      value: '1258549',
    },
    {
      label: 'co-ed',
      value: '1258550',
    },
    {
      label: 'everyone',
      value: '1258560',
    },
  ],
  optionsTranslatedKey: 'sex',
} as const;

export const ZODIAC_FILTER: ListingsFilterToggle<Zodiac> = {
  columns: '4',
  filterTitle: 'zodiac',
  filterType: 'checkbox',
  isOptionsTranslated: true,
  name: FiltersNames.Zodiac,
  options: [
    {
      label: 'capricorn',
      value: '213',
    },
    {
      label: 'aquarius',
      value: '214',
    },
    {
      label: 'pisces',
      value: '215',
    },
    {
      label: 'aries',
      value: '216',
    },
    {
      label: 'taurus',
      value: '217',
    },
    {
      label: 'gemini',
      value: '218',
    },
    {
      label: 'cancer',
      value: '219',
    },
    {
      label: 'leo',
      value: '220',
    },
    {
      label: 'virgo',
      value: '221',
    },
    {
      label: 'libra',
      value: '222',
    },
    {
      label: 'scorpio',
      value: '223',
    },
    {
      label: 'sagittarius',
      value: '224',
    },
  ],
  optionsTranslatedKey: 'zodiac',
  optionsVariant: 'button',
} as const;

export const PET_OWN_FILTER: ListingsFilterToggle<Pet> = {
  columns: '4',
  filterTitle: 'pets',
  filterType: 'checkbox',
  isOptionsTranslated: true,
  name: FiltersNames.PetsOwn,
  options: [
    {
      label: 'cats',
      value: '105',
    },
    {
      label: 'dogs',
      value: '108',
    },
    {
      label: 'small-pets',
      value: '114',
    },
    {
      label: 'birds',
      value: '118',
    },
    {
      label: 'fish',
      value: '120',
    },
    {
      label: 'reptiles',
      value: '122',
    },
  ],
  optionsTranslatedKey: 'pets',
  optionsVariant: 'button',
} as const;

export const PET_PREFERRED_FILTER: ListingsFilterToggle<Pet> = {
  columns: '4',
  filterTitle: 'pets',
  filterType: 'checkbox',
  isOptionsTranslated: true,
  name: FiltersNames.PetsPreferred,
  options: [
    {
      label: 'cats',
      value: '137',
    },
    {
      label: 'dogs',
      value: '140',
    },
    {
      label: 'small-pets',
      value: '148',
    },
    {
      label: 'birds',
      value: '150',
    },
    {
      label: 'fish',
      value: '152',
    },
    {
      label: 'reptiles',
      value: '153',
    },
  ],
  optionsTranslatedKey: 'pets',
  optionsVariant: 'button',
} as const;

export const BEDROOMS_FILTER: ListingsFilterToggle<string> = {
  columns: '2',
  filterTitle: 'bedrooms',
  filterType: 'checkbox',
  name: FiltersNames.Bedrooms,
  options: [
    {
      label: 'Studio',
      value: '1',
    },
    {
      label: '1 bedroom',
      value: '2',
    },
    {
      label: '2 bedrooms',
      value: '6',
    },
    {
      label: '3 bedrooms',
      value: '11',
    },
    {
      label: '4 bedrooms',
      value: '16',
    },
    {
      label: '5 bedrooms',
      value: '20',
    },
  ],
} as const;

export const BATHROOMS_FILTER: ListingsFilterToggle<string> = {
  columns: '2',
  filterTitle: 'bathrooms',
  filterType: 'checkbox',
  name: FiltersNames.Bathrooms,
  options: [
    {
      label: '1',
      value: '27',
    },
    {
      label: '1.5',
      value: '28',
    },
    {
      label: '2',
      value: '29',
    },
    {
      label: '2.5',
      value: '30',
    },
    {
      label: '3',
      value: '31',
    },
    {
      label: '3.5 +',
      value: '32',
    },
  ],
} as const;

export const APARTMENT_AMENITIES_FILTER: ListingsFilterToggle<string> = {
  columns: '3',
  filterTitle: 'apartment-amenities',
  filterType: 'checkbox',
  name: FiltersNames.Amenities,
  options: [
    {
      label: 'High-rise',
      value: '3',
    },
    {
      label: 'Low-rise',
      value: '4',
    },
    {
      label: 'Disability Access',
      value: '5',
    },
    {
      label: 'Doorman',
      value: '7',
    },
    {
      label: 'Elevator',
      value: '8',
    },
    {
      label: 'Walkup',
      value: '9',
    },
    {
      label: 'Health Club',
      value: '10',
    },
    {
      label: 'Laundromat',
      value: '12',
    },
    {
      label: 'Covered Parking',
      value: '13',
    },
    {
      label: 'Garage',
      value: '14',
    },
    {
      label: 'Parking Lot',
      value: '15',
    },
    {
      label: 'Street Parking',
      value: '17',
    },
    {
      label: 'Near Bus Stop',
      value: '18',
    },
    {
      label: 'Near Subway',
      value: '19',
    },
    {
      label: 'Electronic Security',
      value: '21',
    },
    {
      label: 'Security',
      value: '22',
    },
    {
      label: 'Swimming Pool',
      value: '23',
    },
    {
      label: 'Internet',
      value: '24',
    },
    {
      label: 'Wireless Internet',
      value: '25',
    },
  ],
  optionsVariant: 'button',
} as const;

export const LISTINGS_SORT: ListingsFilterToggle<string> = {
  defaultValue: 'LastActivity',
  filterTitle: 'sort',
  filterType: 'radio',
  isOptionsTranslated: true,
  name: FiltersNames.Sort,
  options: [
    { label: 'last-activity', value: 'LastActivity' },
    { label: 'newest', value: 'Newest' },
    { label: 'rent', value: 'MonthlyRent' },
  ],
  optionsTranslatedKey: 'sort',
} as const;
