import {
  deleteCookie,
  getCookie,
} from 'cookies-next';
import { type AppRouterOptions } from 'cookies-next/src/types';

export const userBearerCookieName = '__roomsterUser__';

interface UserBearerCookie {
  __bearer_token__: string;
}

export function getUserBearerCookie(options?: AppRouterOptions): string | null {
  const userBearerCookieValue = getCookie(userBearerCookieName, options);

  if (userBearerCookieValue) {
    const userBearerCookie = JSON.parse(userBearerCookieValue) as UserBearerCookie;

    // eslint-disable-next-line no-underscore-dangle
    return userBearerCookie.__bearer_token__;
  }

  return null;
}

export function deleteUserBearerCookie(options?: AppRouterOptions): true {
  deleteCookie(userBearerCookieName, options);

  return true;
}
