import {
  deleteCookie,
  getCookie,
  setCookie,
} from 'cookies-next';
import { type AppRouterOptions } from 'cookies-next/src/types';

const acceptedGDRPCookieName = 'acceptedGDPR';

export function getCookieAcceptedGDRP(options?: AppRouterOptions): boolean {
  return !!getCookie(acceptedGDRPCookieName, options);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setCookieAcceptedGDRP(gdprData: any): void {
  setCookie(acceptedGDRPCookieName, JSON.stringify(gdprData));
}

export function deleteCookieAcceptedGDRP(): void {
  deleteCookie(acceptedGDRPCookieName);
}
