import {
  getCookie,
  setCookie,
} from 'cookies-next';

import { type CookiesOptions } from '@/libs/next-cookies/next-cookies-type';

const cookieName = 'compaign_referrer_id';
const defaultCookieValue = '00001ad8j';
const compareSeconds = 30;
const durationThirtyDays = 60 * 60 * 24 * 30;

export async function saveReferralCookie(referralId: string, referralTimestamp: number, options?: CookiesOptions) {
  const response = await fetch(`https://api.roomster.com/v1/AffiliateInfo/?campaign=${referralId}`, { cache: 'no-store' });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data = await response.json();

  const secondsDiff = Math.floor(Date.now() / 1000) - referralTimestamp;
  const isMoreThenThirtySeconds = secondsDiff > compareSeconds;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (data.is_external && (isMoreThenThirtySeconds || !referralTimestamp)) {
    setCookie(cookieName, defaultCookieValue, {
      maxAge: durationThirtyDays,
      path: '/',
      ...options,
    });
  } else {
    setCookie(cookieName, referralId, {
      maxAge: durationThirtyDays,
      path: '/',
      ...options,
    });
  }
}

export const saveSearchEngineReferralCookie = (referralId: string, options?: CookiesOptions) => {
  setCookie(cookieName, referralId, {
    maxAge: durationThirtyDays,
    path: '/',
    ...options,
  });
};

export function getReferralCookie(): string | false {
  const cookie = getCookie(cookieName);

  return cookie ?? false;
}
