import {
  getCookie,
  setCookie,
} from 'cookies-next';
import { type AppRouterOptions } from 'cookies-next/src/types';

const cookieName = 'currency';

export function getCookieCurrency(options?: AppRouterOptions): string {
  const cookie = getCookie(cookieName, options);

  return cookie ?? 'USD';
}

export function setCookieCurrency(currency: string): void {
  setCookie(cookieName, currency);
}
