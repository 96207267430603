import {
  getCookie,
  setCookie,
} from 'cookies-next';

import { type CookiesOptions } from '@/libs/next-cookies/next-cookies-type';

const cookieName = 'keyword';
const durationThirtyDays = 60 * 60 * 24 * 30;

export function setKeywordCookie(keyword: string, options?: CookiesOptions) {
  setCookie(cookieName, keyword, {
    maxAge: durationThirtyDays,
    path: '/',
    ...options,
  });
}

export function getKeywordCookie(): string | false {
  const cookie = getCookie(cookieName);

  return cookie ?? false;
}
