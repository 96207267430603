import Image from 'next/image';

import styles from './avatar.module.scss';

interface AvatarProps {
  imageSrc: string;
  alt: string;
}

export default function Avatar(props: AvatarProps) {
  const {
    alt,
    imageSrc,
  } = props;

  return (
    <Image
      className={styles.avatar}
      src={imageSrc}
      alt={alt}
      width={40}
      height={40}
      unoptimized
    />
  );
}
