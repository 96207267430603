import { type InputProps } from 'react-select';
import { components } from 'react-select';

import { type Place } from '@/features/place/types/place-types';

export default function SearchPlaceInput(props: InputProps<Place>) {
  return (
    <components.Input
      {...props}
      // aria-activedescendant="search-place"
      // aria-label="search-place"
      // araria-labelledby="search-place"
      contentEditable
      isHidden={false}
      hidden={false}
      // role="search"
      inputMode="text"
      id="search-place"
    />
  );
}
