import { createSharedPathnamesNavigation } from 'next-intl/navigation';

import {
  LOCALE_PREFIX,
  SUPPORTED_LOCALES,
} from '@/features/language/language-constants';

export const {
  Link,
  redirect,
  usePathname,
  useRouter,
} = createSharedPathnamesNavigation({ localePrefix: LOCALE_PREFIX, locales: SUPPORTED_LOCALES });
