import {
  getCookie,
  setCookie,
} from 'cookies-next';
import { type AppRouterOptions } from 'cookies-next/src/types';

import { LISTING_URL_PART_VARIANTS } from '@/features/listing/constants/listing-constants';
import { type CurrentPlace } from '@/features/place/types/place-types';

export const currentPlaceCookieName = 'currentPlace';
// need to keep synchronized old and new app
export const oldAppSearchLocationCookie = 'searchLocation';
export const oldAppGeolocationCookie = 'geolocation';

export function getCookieCurrentPlace(options?: AppRouterOptions): CurrentPlace | null {
  const cookie = getCookie(currentPlaceCookieName, options);

  if (cookie) {
    return JSON.parse(cookie) as CurrentPlace;
  }

  return null;
}

export function setCookieCurrentPlace(place: CurrentPlace, options?: AppRouterOptions): void {
  const geolocationCookieDataKey = `${place.fullName.replaceAll(',', '').replaceAll(' ', '-').toLowerCase()}-${LISTING_URL_PART_VARIANTS[place.listingType]}`;
  const geolocationCookieData = {
    [geolocationCookieDataKey]: {
      geo: {
        country_code: null,
        lat_ne: place.bbox.latitudeNorthEast,
        lat_sw: place.bbox.latitudeSouthWest,
        lng_ne: place.bbox.longitudeNorthEast,
        lng_sw: place.bbox.longitudeSouthWest,
        radius_scale: 1,
      },
      searchLocation: place.fullName,
    },
  };

  setCookie(currentPlaceCookieName, place, options);
  setCookie(oldAppSearchLocationCookie, place.fullName, options);
  setCookie(oldAppGeolocationCookie, geolocationCookieData);
}
