import { type CurrencyRates } from '../types/currency-types';

export default async function getCurrenciesRates(): Promise<CurrencyRates> {
  const result = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/v1/currencyrates`,
    { cache: 'no-store' },
  );

  // TODO: rewrite to flow without break APP
  if (!result.ok) {
    throw new Error('Failed to fetch data');
  }

  return result.json() as Promise<CurrencyRates>;
}
