import {
  deleteCookie,
  getCookie,
  setCookie,
} from 'cookies-next';
import { type AppRouterOptions } from 'cookies-next/src/types';

import { type Place } from '@/features/place/types/place-types';

const cookieName = 'lastSearchedPlaces';

export function getCookieLastSearchedPlaces(options?: AppRouterOptions): Place[] | null {
  const cookie = getCookie(cookieName, options);

  if (cookie) {
    return JSON.parse(cookie) as Place[];
  }

  return null;
}

export function setCookieLastSearchedPlaces(places: Place[]): void {
  setCookie(cookieName, places);
}

export function deleteCookieLastSearchedPlaces(): void {
  deleteCookie(cookieName);
}
