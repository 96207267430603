'use client';

import { deleteCookie } from 'cookies-next';
import { useTranslations } from 'next-intl';
import {
  FaAngleRight,
  FaArrowRightFromBracket,
  FaBookOpenReader,
  FaBullhorn,
  FaEnvelope,
  FaGear,
  FaHeadset,
  FaQuestion,
  FaRegHeart,
  FaRegMessage,
  FaSistrix,
  FaUser,
} from 'react-icons/fa6';
import {
  MdOutlineGridView,
  MdOutlineHelpOutline,
} from 'react-icons/md';

import { type UserListings } from '@/features/listing/types/user-listings-types';
import getAppFlyerLink from '@/features/roomster-app/app-flyer-links/get-app-flyer-link';
import { deleteUserBearerCookie } from '@/features/user/cookies/cookie-user-bearer';
import {
  type MessagesCounters,
  MessagesType,
} from '@/features/user/types/message-counters-types';
import { VerificationStatus } from '@/features/user/types/user-id-verification-types';
import { type User } from '@/features/user/types/user-types';
import { deleteCookieAcceptedGDRP } from '@/layout/cookie-accept-popup/cookies/cookie-accepted-gdrp';
import { deleteCookieUUID } from '@/layout/cookie-accept-popup/cookies/cookie-uuid';
import styles from '@/layout/menu/menu.module.scss';
import {
  Link,
  useRouter,
} from '@/navigation';
import {
  EXTERNAL_LINKS,
  ROUTER_LINKS,
} from '@/shared/constants/links-constants';
import NotificationsIndicator from '@/ui/notification-indicator/notifications-indicator';

/* eslint-disable canonical/prefer-react-lazy */

interface NavigationProps {
  user: User | null;
  messagesCounters: MessagesCounters | null;
  helpdeskNewNotificationsCount: number | null;
  userListingsData: UserListings | null;
  closeMenu: () => void;
}

export default function Navigation(props: NavigationProps) {
  const {
    closeMenu,
    helpdeskNewNotificationsCount,
    messagesCounters,
    user,
    userListingsData,
  } = props;

  const router = useRouter();

  const translationsLinks = useTranslations('global.links');
  const translationsActions = useTranslations('global.actions');
  const translationsMenu = useTranslations('layout.menu');
  const placeAppFlyerLink = getAppFlyerLink('mobile', 'mobileMenuListYourSpaceButton');

  const inboxMessages = messagesCounters?.[MessagesType.Inbox];

  const handleLogout = () => {
    new Promise((resolve) => {
      resolve((() => {
        deleteUserBearerCookie();
        deleteCookieAcceptedGDRP();
        deleteCookieUUID();
        deleteCookie('loggedUserLocalizationSettings');

        return true;
      })());
    })
      .then(() => {
        router.replace(ROUTER_LINKS.home);
      })
      .catch(() => null);
  };

  return (
    <ul className={styles.nav}>
      {user ? (
        <>
          <li>
            <button
              className={styles.nav_link}
              onClick={closeMenu}
            >
              <FaSistrix className={styles.nav_icon} />
              {translationsLinks('search')}
              <FaAngleRight className={styles.nav_arrowIcon} />
            </button>
          </li>

          <li>
            <Link
              className={styles.nav_link}
              href={ROUTER_LINKS.bookmarks}
            >
              <FaRegHeart className={styles.nav_icon} />
              {translationsLinks('bookmarks')}
              <FaAngleRight className={styles.nav_arrowIcon} />
            </Link>
          </li>

          <div className={styles.nav_divider} />

          <li>
            <Link
              className={styles.nav_link}
              href={`${ROUTER_LINKS.profile}/${String(user.id)}`}
            >
              <FaUser className={styles.nav_icon} />
              {translationsLinks('my-profile')}
              {(!user.verifications.id || user.verifications.id.status === VerificationStatus.New) && (
                <div className={styles.nav_indicatorsArea}>
                  <div className={styles.nav_completeBadgeText}>{translationsMenu('complete-profile')}</div>
                </div>
              )}
              <FaAngleRight className={styles.nav_arrowIcon} />
            </Link>
          </li>

          <li>
            <Link
              className={styles.nav_link}
              href={`${ROUTER_LINKS.profile}/${String(user.id)}${ROUTER_LINKS.listings}`}
            >
              <MdOutlineGridView className={styles.nav_icon} />
              {translationsLinks('my-listings')}
              {userListingsData && userListingsData.count > 0 && (`(${String(userListingsData.count)})`)}
              {userListingsData?.isDataMissing && (
                <div className={styles.nav_indicatorsArea}>
                  <div className={styles.nav_completeBadgeText}>{translationsMenu('complete-listings')}</div>
                </div>
              )}
              <FaAngleRight className={styles.nav_arrowIcon} />
            </Link>
          </li>

          <div className={styles.nav_divider} />

          <li>
            <Link
              className={styles.nav_link}
              href={ROUTER_LINKS.messages}
            >
              <FaRegMessage className={styles.nav_icon} />
              {translationsLinks('messages')}
              <div className={styles.nav_indicatorsArea}>
                {!!inboxMessages && inboxMessages.countDirectNew > 0 && (
                  <div className={styles.nav_messageIndicator}>
                    <NotificationsIndicator
                      icon={FaEnvelope}
                      text={inboxMessages.countDirectNew > 999 ? '999+' : inboxMessages.countDirectNew}
                    />
                  </div>
                )}

                {!!inboxMessages && inboxMessages.countMegaphoneNew > 0 && (
                  <div className={styles.nav_messageIndicator}>
                    <NotificationsIndicator
                      icon={FaBullhorn}
                      color="blue"
                      text={inboxMessages.countMegaphoneNew > 999 ? '999+' : inboxMessages.countMegaphoneNew}
                    />
                  </div>
                )}
              </div>

              <FaAngleRight className={styles.nav_arrowIcon} />
            </Link>
          </li>

          <li>
            <Link
              className={styles.nav_link}
              href={ROUTER_LINKS.megaphone}
            >
              <FaBullhorn className={styles.nav_icon} />
              {translationsLinks('megaphone')}
              <FaAngleRight className={styles.nav_arrowIcon} />
            </Link>
          </li>

          <div className={styles.nav_divider} />

          <li>
            <Link
              className={styles.nav_link}
              href={ROUTER_LINKS.settings}
            >
              <FaGear className={styles.nav_icon} />
              {translationsLinks('settings')}
              <FaAngleRight className={styles.nav_arrowIcon} />
            </Link>
          </li>

          <li>
            <Link
              className={styles.nav_link}
              href={EXTERNAL_LINKS.faq}
            >
              <FaQuestion className={styles.nav_icon} />
              {translationsLinks('faq')}
              <FaAngleRight className={styles.nav_arrowIcon} />
            </Link>
          </li>

          <li>
            <Link
              className={styles.nav_link}
              href={ROUTER_LINKS.tutorial}
            >
              <FaBookOpenReader className={styles.nav_icon} />
              {translationsLinks('tutorial')}
              <FaAngleRight className={styles.nav_arrowIcon} />
            </Link>
          </li>

          <li className={styles.nav_item}>
            {!!helpdeskNewNotificationsCount && helpdeskNewNotificationsCount > 0 && (
              <div
                className={styles.nav_messageIndicatorHelpdesk}
              >
                <NotificationsIndicator
                  color="orange"
                  text={helpdeskNewNotificationsCount > 99 ? '99+' : helpdeskNewNotificationsCount}
                />
              </div>
            )}

            <Link
              className={styles.nav_link}
              href={ROUTER_LINKS.support}
            >
              <FaHeadset className={styles.nav_icon} />
              {translationsLinks('support')}
              <FaAngleRight className={styles.nav_arrowIcon} />
            </Link>
          </li>

          <div className={styles.nav_divider} />

          <li className={styles.nav_logout}>
            <button
              className={styles.nav_link}
              onClick={handleLogout}
            >
              <FaArrowRightFromBracket className={styles.nav_icon} />
              {translationsActions('logout')}
            </button>
          </li>
        </>
      ) : (
        <>
          <li>
            <Link
              className={styles.nav_link}
              href={placeAppFlyerLink}
              passHref
              target="_blank"
            >
              <MdOutlineGridView className={styles.nav_icon} />
              {translationsLinks('list-space')}
            </Link>
          </li>

          <li>
            <Link
              className={styles.nav_link}
              href={ROUTER_LINKS.phoneAuth}
            >
              <MdOutlineHelpOutline className={styles.nav_icon} />
              {translationsLinks('help')}
            </Link>
          </li>
        </>
      )}
    </ul>
  );
}
